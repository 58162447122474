import React from "react"
import Loadable from 'react-loadable'
import styled from "styled-components"
import { useSelector } from 'react-redux'
import YAML from 'yaml'
import { dark, highlight, light } from "../components/styles"

import Seo from "../components/seo"
import { Link } from "gatsby"


const InfoPageMain = styled.div`
  display: grid;
  grid-template-columns: 5% 55% 40%;
  grid-template-rows: 5vh 70vh;
  @media (max-width: 768px){
    grid-template-columns: 100%;
    grid-template-rows: 50vh auto;
    grid-row-gap: 40px;
  }
  align-items: center;
  justify-items: center;
`
const AboutTextContainer = styled.div`
  width: 90%;
  align-self: start;
  height: 100%;
  grid-column-start: 2;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
  @media (max-width: 768px) {
    grid-column-start: 1;
  }
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 80%;
  grid-row-start: 2;
  align-items: start;
`
const AboutTitle = styled.div`
  grid-row-start: 1;
  grid-column: 1;
  align-self: center;
`

const AboutText = styled.div`
  grid-row-start: 2;
  font-weight: bolder;
  font-size: 1.1rem;
  grid-column: 1 / span 2;
`
const EditorContainer = styled.div`
  align-self: start;
  justify-self: start;
  width: 90%;
  height: 80%;
  grid-column-start: 3;
  grid-row-start: 2;
  @media (max-width: 768px) {
    grid-row-start: 1;
    margin-top: 10%;
    justify-self: center;
    grid-column-start: 1;
  }
  border-radius: 20px;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
`
const InfoLink = styled(Link)`
  text-decoration: none;
  color: ${highlight};
`
const InfoLinkExternal = styled.a`
  text-decoration: none;
  color: ${highlight};
`

const LoadableEditor = Loadable({
  loader: () => import('../components/elements/editor'),
  loading() {
    return <div>Loading...</div>
  }
});

const infoPage: React.FC = () => {
  let info = undefined
  try {
    info = YAML.parse(useSelector(state => state.editor.value)).info
  } catch (e) {
    console.log(e)
  }
  const name = info ? info.name : undefined
  const occupation = info ? info.occupation : undefined
  const webFrontInfo = info ? info.specializations.webFrontend[0] : undefined
  const mobileFrontInfo = info ? info.specializations.mobileApplications[0] : undefined

  return <>

    <Seo title="info"/>
    <InfoPageMain>
      <AboutTextContainer>
        <AboutTitle>
          <h1>A little bit about me:</h1>
        </AboutTitle>
        <AboutText>
          <p>
            Hi, my name is {name} and I am a {occupation} specializing in {webFrontInfo} and {mobileFrontInfo}. I've been lucky enough to live all over the world,
            (currently in Tokyo), but its time for me to get back to the USA. I'm currently looking for full time or freelance jobs, either remotely or in the Boston area, working on interesting {webFrontInfo} and {mobileFrontInfo} applications. Feel free to check out my <InfoLinkExternal target="_blank" href={"https://www.mfisher.dev/documents/CVMatthewFisher.pdf"}>resume </InfoLinkExternal>
             and <InfoLink to={'/portfolio'}>my portfolio</InfoLink>, or <InfoLink to={'/contact'}>contact me here</InfoLink> if you're looking for an engineer. You can also visit my <InfoLinkExternal target="_blank" href={'https://www.linkedin.com/in/matthew-r-fisher/'}>LinkedIn</InfoLinkExternal> or <InfoLinkExternal target="_blank" href={'https://github.com/Karstagg'}>Github</InfoLinkExternal>.
          </p>
          <p>
            In addition to programming i'm also an avid traveler, techie, audiophile, language nerd, and foodie / amateur chef. Check out my <InfoLink to={'/blog'}>blog</InfoLink>!
          </p>
        </AboutText>
      </AboutTextContainer>
      <EditorContainer>
        <LoadableEditor name={"main info"} height="100%" width="100%"/>
      </EditorContainer>
    </InfoPageMain>
  </>
}
export default infoPage
